/**
 * Text input field
 * @param {object} params
 * @param {string} params.label - Label of the text field
 * @param {boolean} params.required - Whether or not this field is required
 */
function TextField({ label, required, initial, value, className, ...restProps }) {
  const touched = value !== undefined && initial !== value
  return (
    <div className={'form-field' + (!value ? ' error' : '')}>
      {label && <div className="title">{label + (required ? '*' : '')}</div>}
      <input
        className={className || '' + (touched ? ' touched' : '')}
        value={value ?? ''}
        {...restProps}
        required={required}
      ></input>
    </div>
  )
}

export default TextField
