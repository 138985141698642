import { action, thunk } from 'easy-peasy'

const Auth = {
  user: null,

  setUser: action((state, payload) => {
    state.user = payload
  }),

  updateUser: thunk((actions, payload, { getState }) => {
    let user = payload.username !== '' ? payload.username : payload.idTokenClaims.signInName

    if (user !== getState().user) {
      actions.setUser(user)
    }
  }),
}

export default Auth
